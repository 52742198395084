/* # Base-v11

Stylesheet for normalisation and basic elements definition
It is used in every web page, landing page and interstitial page

Is included to
  
* common-web-v11.css
* common-landingpage-responsive-v11.css
* common-landingpage-v11.css
* common-interstitial-v11.css

 */

/* # Imports */

/* Importing variables, mixins and components */
@import 'mixins.less';
@import 'variables.less';
@import 'fonts/fonts.less';
@import 'components/buttons.less';
@import 'components/text-styles.less';
@import 'components/tabs.less';


/* # Border box model */
HTML {
  box-sizing: border-box;
}
*, 
*:before, 
*:after {
  box-sizing: inherit;
}

/* # General elements */
HTML, BODY {
	height: 100%;
}
BODY {
	position: relative;
	margin: 0;
	padding: 0;
	background-color: @BodyBackground;
	text-align: left;
	}

IMG {
	padding:0;
	margin:0;
}

FORM {
	margin:0;
	padding:0;
}

HR {
	color: transparent;
	height: 1px;
	border: none;
	border-bottom: 1px solid #EAEBEC;
	margin: 0;
}

TABLE {
	border-collapse: collapse;
	margin: 0px;
	padding: 0px;
	
	TD {
	margin: 0px;
	padding: 0px;
	}
}


/* # Hyperlinks */
A IMG {
	border: 0;
}


/* # Floats and clears */
.left {
	float:left;
}

.right {
	float:right;
}

.clear {
	clear:both;
}

.clearF{
	margin:0;
	line-height:0;
	padding:0;
	border:none;
	height:0;
	font-size:0;
	clear:both;
}
.clearfix:before,
.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

.none {
	clear:none;
}
.through {
	text-decoration: line-through;
	}

/* # Focus 

Chrome fix for bordering focused elements */

*:focus {outline: none;}


/* # Text decorations

Those are simply clases for basic text decorations

* underline
* line-through
* nowrap

 */
.underline{
	text-decoration:underline;
}
.line-through{
	text-decoration:line-through;
}
.nowrap {
  white-space: nowrap;
}


/* # Media content

Defines element visibility for different media contexts

* desktop
* tablet
* mobile

 */

/* #### desktop */
@media @desktop {
.tablet,
.mobile,
.hidden-desktop {
  display: none !important;
}
}

/* #### tablet */
@media @tablet {
.desktop,
.mobile,
.hidden-tablet {
    display: none !important;
  }
}

/* #### mobile */
@media @mobile {
.desktop,
.tablet,
.hidden-mobile {
    display: none !important;
  }

}



/* # GRID 

Element width is calculated as x/12 of page width

You don't need to use full width (12 columns) - in that case row is by default centered

```
<div class="row">
<div class="span6"><p>Span 6</p></div>
<div class="span3"><p>Span 3</p></div>
<div class="span3"><p>Span 3</p></div>
</div>
	```

*/


/* #### Desktop resolution  */

/* gutter for the grid */
.AVsizer {
	padding-left: 20px !important;
  	padding-right: 20px !important;
	}

.row {
	*zoom: 1;
	margin-left: -20px;
	margin-right: -20px;

  	&:before,
  	&:after {
    	display: table;
    	line-height: 0;
    	content: "";
  	}
  	&:after {
    	clear: both;
  	}
  	[class*="span"] {
	  	float: left;
    	min-height: 1px;
    	padding: 20px;
  	}
}  

/* switching left and right column */
.row.switch [class*="span"] {
    float: right;
 }
 /*  nopadding row */
  .row.nopadding [class*="span"] {
    padding: 0px;
  }


  /* rowspan definitions */
  .span12 {
    width: 100%;
  }
  .span9 {
    width: 75%;
  }
  .span8 {
    width: 66.66666665%;
  }
   .span7 {
    width: 58.33333332%;
  }
  .span6 {
    width: 50%;
  }
   .span5 {
    width: 41.66666665%;
  }
  .span4 {
    width: 33.33333332%;
  }
  .span3 {
    width: 25%;
  }



/* #### Tablet and mobile resolution overwrite */

@media @tablet, @mobile {

  /* fixing size of the images inside grid */
  [class*="span"] > img {
    max-width: 100%;
  }
}


@media @mobile {

.row {
    *zoom: 1;
    width: auto;
    display: block;
  
	[class*="span"]  {
		float: none;
	    min-height: 1px;
	    padding: 20px;
		width: auto;
		display: block;
  	}
}

.row.switch [class*="span"]  {
	float: none;
  	}
.row.nopadding [class*="span"]  {
    padding: 0 0 20px;
  }

}
 


/* # Visibility

Specifies element visibility

  * .hide {display:none;}
  * .hidden {visibility:hidden;}
  * .visible {visibility:visible;}
  * .block {display:block;}
  * .inline {display:inline;}

 */

.hide {display:none;}
.hidden {visibility:hidden;}
.visible {visibility:visible;}
.block {display:block;}
.inline {display:inline;}




/* # Tooltip

Styles for tooltip
Please note that <em> element is reserved only for tooltip, so do not use it in your code

```<div id="tooltip" class="tooltip-bubble" style="top: 2122.65px; left: 1349.18px; display: none;">
<em>A software or hardware device designed and configured to allow access for authorized communications and deny any transmissions that are unauthorized.</em>
</div>```

 */

.tooltip-bubble {
	position: absolute;
	width: 250px;
	border: 1px solid @ContentBorder;
	border-radius: 3px;
	padding: 10px 12px;
	box-shadow: 0px 1px 3px @Grey-8;
	z-index: 9999;
	background-color: @White;
	background: linear-gradient(@White, @Grey-9);
}
.tooltip-bubble:before {
   content: "";
   position: absolute;
   top: -10px;
   left: 20px;
   width: 0;
   height: 0;
   border-bottom: 9px solid @ContentBorder;
   border-left: 9px solid rgba(255, 255, 255, 0);
   border-right: 9px solid rgba(255, 255, 255, 0);
   z-index: 9998;
}
.tooltip-bubble:after {
   content: "";
   position: absolute;
   top: -8px;
   left: 20px;
   width: 0;
   height: 0;
   border-bottom: 9px solid @White;
   border-left: 9px solid rgba(255, 255, 255, 0);
   border-right: 9px solid rgba(255, 255, 255, 0);
   z-index: 10000;
}
/* <em> is reserved for tooltip, do not use within page content */
.tooltip-bubble em {
	font-size: 14px;
	font-style: normal;
}


/* # Colorbox

Styles for Colorbox
content-box sizing fix by Andrej P.
*/

#colorbox,
#cboxWrapper{position:absolute; top: 25px; left: 50px; z-index:9999; overflow:visible;}
#cboxOverlay{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;
	background-color: transparent;
	}

#colorbox {
	padding: 25px 50px 25px;
	zoom: 1;
	background-color: #FFF;
	border-radius: 3px;
    box-shadow: 0 0 70px 0 #888;
    box-sizing:content-box;
	}
#colorbox BUTTON {border: none;}


#cboxLoadedContent {
	font-size: 13px;
	background-color: transparent;
	}

#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height: 100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block;}
.cboxIframe{width:100%; height:100%; display:block; border:0;}


#cboxOverlay{background:#000;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxTitle{ position:absolute; top:-20px; left:0; color:#ccc; display: none!important; }
        #cboxCurrent{position:absolute; top:-20px; right:0px; color:#fff;}
        #cboxSlideshow{position:absolute; top:-20px; right:90px; color:#fff;}
		#cboxPrevious, #cboxNext {position:absolute; top: 0; width: 50px; height:100%; text-indent:-9999px; background-color: transparent; background-image: url('../i/colorbox/colorbox-arrows-v9.png'); background-repeat: no-repeat;}
        #cboxPrevious{left: -50px; background-position: 8px 50%;}
        #cboxNext{right: -50px; background-position: -112px 50%;}
        #cboxLoadingOverlay{background:#2E3640;}
        #cboxLoadingGraphic{background:#2E3640 url('../i/clearbox-loading.gif') no-repeat center center;}
        #cboxClose{border:0;padding:0;margin:0;position:absolute; top:-10px; right:-35px; display:block; background:url('../i/close.png') no-repeat top center; width: 22px; height:22px; text-indent:-9999px; z-index: 100}

/* fixing elements */
		#cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight, #cboxMiddleLeft, #cboxMiddleRight  {
			display: none;
			}
		#colorbox.cboxIE {*padding-bottom: 0px !important;}

/* # No javascript message

Common element in the top of the page
 */
.noscript-message {
	background: none repeat scroll 0 0 #ff7777;
	padding: 10px;
	text-align: center;
}
.noscript-message a {
	&:visited,
	&:hover {
		color: #171B20;
	}
}

/* # Specials */

/* iframe.avast.com cookie request */
.iframe-blank {
	width: 0;
	height: 0;
	visibility: hidden;
}


/* background for cells in comparison table with tick - class added by JS - cell-tick */
.cell-tick{
  background-color: #f2f2f6;
}