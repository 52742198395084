/* # Chrome banner

Chrome promo banner used on several pages, both web nad lp

snippet is {google-chromebox-home}

 */

@import '../variables.less';

#offer-chrome {
	display: none;

	.AVsizer {
    padding-top: 30px;
    padding-bottom: 30px;
	}
	P {
	    padding: 15px 0;
	}
	#logo-chrome {
		text-align: right;

		IMG {
			margin: 0 auto;
		}
	}
	#button-chrome {
	    padding-top: 30px;

		    .button {
		    margin: 0 auto;
		}
	}

	@media @mobile {
		[class*="span"] {
			text-align: center !important;
		}
	}

}

/* localisation */
[class*="mod-de"],
[class*="mod-nl"] { 
	#offer-chrome P {
    	padding-top: 7px;
	}
}