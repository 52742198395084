/* # Basic page elements
==========

Provides styling for page elements, that are used both on the web and landingpages

So no to repeat thir definiton on
comon-web.css
and
common-landingpage.css
they are put together in this component, that is included in both stylesheets

*/

@import '../variables.less';


/* # Content holder definition */
#content-holder {
	text-align: center;
	position: relative;
	background-color: @ContentWhiteBackground;
	margin: 0px;
	padding: 0px;
	}

/*  # AV sizer definition
defines width of main elements content - header, content, footer */
.AVsizer {
	margin: 0px auto;
	text-align: left;
	position: relative;

		@media @mobile {
			text-align: center;
		}
	}


/* # Top element

## Standard top element

```
	<div id="top">
		<div class="AVsizer">
		</div>
	</div>
```
 */
#top {
	background-color: @ContentTopBackground;

	H1, H2 {
		color: @ContentTopTitle;
	}
	P {
		color: @ContentTopText;
	}
}

/* # Background colors

Basic background colors

```
<div id="content-holder">
	<div id="" class="bg-grey">
		<div class="AVsizer">
		</div>
	</div>
</div>
```

 */

/* ### Light backgrounds */ 
.bg-white {
  background-color: @ContentWhiteBackground;
  }
.bg-grey {
	background-color: @ContentLightBackground;
	}


/* ### Dark backgrounds */ 
.bg-dark {
	background-color: @ContentDarkBackground;
	color: @ContentDarkText;

	H1,
	H2,
	H3,
	H4,
	H5 {
		color: @ContentDarkTitle;
	}
	P {
		color: @ContentDarkText;
	}
}


.bg-darker {
	background-color: @ContentDarkerBackground;
	color: @ContentDarkerText;

	H1,
	H2,
	H3,
	H4,
	H5 {
		color: @ContentDarkerTitle;
	}
	P {
		color: @ContentDarkerText;
	}
}

.bg-orange  {
	background-color: #FF9000;
	color: #000;
	}
/* old repaint */
.bg-blue-grad {
	background-color: #f4f5f6;
}


/* box */
.AVbox {
	margin: 0 auto;
	background-color: #FFF;
	border: 1px solid #B8C0C9;
	text-align: left;
	position:relative;
	zoom: 1;
    border-radius: 8px;
    background: linear-gradient(to bottom,  #ffffff 80%,#efefef 100%);
}


/* # New/improved ico

Image icons with new / improved text

 */

IMG.txt-ico {
	position: relative;
	top: 3px;
	margin-right: 10px;
	}


/* # Slide to top icon 

Slide to the top section 

*/
#slide-top{
  cursor:pointer;
  position:fixed;
  bottom: 40px;
  right:50%;
  width:70px;
  height:70px;
  line-height:70px;
  margin-right:-630px;
  display:none;
  text-align:center;
  zoom: 1;
  background: url("../i/arrows/slide-top.png") no-repeat scroll 50% 50% transparent;
  z-index: 500;

  SPAN {
		display: none;
	}

}

/* ## Media

Slide top icon is displayed only for viewports bigger than 1240px

 */
@media all and (max-width: 1240px) {
	#slide-top {
		display: none !important;
	}
}
@media all and (min-width:1241px) {
  #slide-top {
      position:fixed;
	  	bottom: 40px;
	  	right:7px;
	  	margin-right: 0;
  }
}
@media all and (min-width:1280px) {
	#slide-top{
	  	position:fixed;
	  	bottom: 40px;
	  	right:20px;
	  	margin-right: 0;
	}
}


/* # Rounded elements

Probably used only to display circular images, IE8 fallback are square images
 */
#content-holder .circle {
  border-radius: 50%;
}


/* # SLIDER */
.slider {
    position: relative;
    }
.slider [class*="slide"] {
	*position: relative !important; /*  IE 7 fix */
    }
.slider .s-nav {
    display: block;
    position: absolute;
    height: 100%;
    width: 60px;
    position: absolute;
    top: 0;
    z-index: 500
    }
.slider .s-nav.prev {
    left: 0;
    }
.slider .s-nav.next {
    right: 0;
    }
.slider .s-nav SPAN {
    position: absolute;
    top: 40%;
    display: block;
    width: 40px;
    height: 80px;
    background-image: url(../i/product-home/slider-arrows.png);
    background-repeat: no-repeat;
    }
.slider .s-nav.prev SPAN {
    background-position: 0% 100%;
    right: 0;
    }
.slider .s-nav.next SPAN {
    background-position: 100% 100%;
    left: 0;
    }
.slider .s-nav.prev:hover SPAN {
    background-position: 0% 0%;
    }
.slider .s-nav.next:hover SPAN {
    background-position: 100% 0%;
    }

.slider .control {
	text-align: center;
	position: absolute;
	left: 48%;
	bottom: 20px;
	z-index: 99;
	text-align: center;
	}

.slider .control SPAN.bullet {
	display: inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
	margin: 3px;

    zoom: 1;
    position: relative;
    top: 3px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
	background-color: #FFF;
	 border: 1px solid #00A7D1;
	}

.slider .control .active SPAN.bullet {
    background-color: #00A7D1;
    border: 1px solid #00A7D1;
 }

 .slider .control .bx-pager-item {
	 float: left;
	 }
 .slider .s-nav {
    opacity: 0;
    transition: opacity .25s ease-in-out;
  }
 .slider:hover .s-nav {
    opacity: 1;
  }
  .slide-text .span4 {
  	position: relative;
  }
  .slide-text .active:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 50%;
	border: solid transparent;
	border-width: 14px;
	border-bottom-color: #f4f5f6;
	top: -31px;
	z-index: 1000;
}
