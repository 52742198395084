@charset "utf-8";
/* CSS Document */

/* imports */
@import 'style-guide/base-v11.less';
@import 'style-guide/components/basic-page-elements-v12.less';
@import 'style-guide/components/banner-chrome.less';



/*  # HEADER

Absolute positioned transparent header
Prevously used on all pages, now only on landingpages
 */
#header {
	position: absolute;
    left: 0;
    top: 0;
	overflow: hidden;
	z-index: 1000;
	text-align: center;
	margin: 0;
	padding: 0;
	width: 100%;
}


/* Header for image backgrounds */
#header.lp-image {
  background-color: transparent;
  background-image: none;
  position: absolute;
  z-index: 1000;
}
#header.lp-image P {
  color: @HeaderText;
}


/* ### Top padding for first div element in #content-holder

Creates 100px padding to move content of that element
This is due to absolutely positioned transparent header
Moved from basic-page-elements.less 
*/
#content-holder > div:first-child { padding-top: 100px; }


/* ## Fullscreen top element

Inside AVsizer is .absolute-center element, which is verticaly centered

Defines vertical centering and cover background
In custom CSS you can define only background-image and eventualy redefine background color

```
	<div id="top" class="fullscreen">
		<div class="AVsizer">
			<div class="absolute-center">
			</div>
		</div>
	</div>
```

 */

#top.fullscreen {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

	.AVsizer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		resize: vertical;
		padding-bottom: 100px;

		/* ## Height calculations */
		@media @desktop, @tablet {
			min-height: calc(100vh - 160px);
		}
		@media @mobile {
			min-height: 0;
		}

		.absolute-center {
			resize: vertical;
		}
		H1, H2, P {
			text-shadow: 0 1px 1px rgba(0,0,0,0.5);
		}
		.button {
			text-shadow: none;
		}
		/* text shadows for Aboxes are specified separately in the abox component */
	}
}

/* IE 11 only */
*::-ms-backdrop, #top.fullscreen .AVsizer { 
	height: calc(100vh - 100px);
	} 
@media @tablet, @mobile {
	*::-ms-backdrop, #top.fullscreen .AVsizer { 
		height: auto;
	} 
}


/* box */
.AVbox {
	margin: 0 auto;
	background-color: #FFF;
	border: 1px solid #B8C0C9;
	text-align: left;
	position:relative;
	zoom: 1;
    border-radius: 8px;
    background: linear-gradient(to bottom,  #ffffff 80%, #efefef 100%); /* W3C standard */
}



/* new/improved ico */
IMG.txt-ico {
	position: relative;
	top: 3px;
	margin-right: 10px;
	}



/* product icons - 80px */
.avast-premier-p {
	background-image: url(../i/product/avast-premier.png);
	}
.avast-backup-p {
	background-image: url(../i/product/avast-backup.png);
	}
.avast-easypass-p {
	background-image: url(../i/product/avast-easypass.png);
	}
.avast-secureline-p {
	background-image: url(../i/product/avast-secureline.png);
	}
.google-chrome-p {
	background-image: url(../i/product/google-chrome.png);
	}
.avast-creditalert-p  {
	background-image: url(../i/product/avast-creditalert.png);
	}
.avast-quickinstall-p  {
	background-image: url(../i/product/avast-quickinstall.png);
	}
.avast-free-edu-p {
	background-image: url(../i/product/avast-free-edu.png);
	}



/* # Media query imports

Imports elements layout and functionality based on resolution

    * common-landingpage-responsive-desktop.less
    * common-landingpage-responsive-tablet.less
    * common-landingpage-responsive-mobile.less

*/

@media @tablet, @desktop {
        @import 'style-guide/components/common-landingpage-responsive-desktop.less';
    }
@media @tablet {
        @import 'style-guide/components/common-landingpage-responsive-tablet.less';
    }
@media @mobile {
        @import 'style-guide/components/common-landingpage-responsive-mobile.less';
    }