/* # Webfonts import  */

/* # Proxima Nova

.eot?#iefix is for IE6 - IE8
.woff2 is for latest versions of modern borwsers
.woff is for previous versions of modern borwsers
.ttf is for Safari, Android and iOS
 */

/* light - font-weight: 300 */
@font-face {
  font-family: 'Proxima Nova';

  src: url('../o/f/300/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../o/f/300/proximanova-light-webfont.woff2') format('woff2'), 
       url('../o/f/300/proximanova-light-webfont.woff') format('woff'), 
       url('../o/f/300/proximanova-light-webfont.ttf')  format('truetype'); 

  font-style: normal;
  font-weight: 300; 
}

/* light - font-weight: 300, font-style: italic */
@font-face {
  font-family: 'Proxima Nova';

  src: url('../o/f/300/proximanova-lightit-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../o/f/300/proximanova-lightit-webfont.woff2') format('woff2'), 
       url('../o/f/300/proximanova-lightit-webfont.woff') format('woff'), 
       url('../o/f/300/proximanova-lightit-webfont.ttf')  format('truetype'); 

  font-style: italic;
  font-weight: 300;
}

/* regular - font-weight: 400 */
@font-face {
  font-family: 'Proxima Nova';

  src: url('../o/f/400/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../o/f/400/proximanova-regular-webfont.woff2') format('woff2'), 
       url('../o/f/400/proximanova-regular-webfont.woff') format('woff'), 
       url('../o/f/400/proximanova-regular-webfont.ttf')  format('truetype'); 

  font-style: normal;
  font-weight: 400;
}

/* bold - font-weight: 700 */
@font-face {
  font-family: 'Proxima Nova';

  src: url('../o/f/700/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'), 
       url('../o/f/700/proximanova-bold-webfont.woff2') format('woff2'), 
       url('../o/f/700/proximanova-bold-webfont.woff') format('woff'), 
       url('../o/f/700/proximanova-bold-webfont.ttf')  format('truetype'); 

  font-style: normal;
  font-weight: 700;
}
