/* # BUTTONS

Styles for creating all types of buttons

Html syntax is

```<a class="button COLOR SIZE OTHER"><span>Text</span></a>```

Contains
  * general button definition
  * sizes
  * colors
  * arrows
  * special buttons

 */
@import '../variables.less';


/* # General button definition

Defines basic button behaviour

* class .button
* hover state normalization
* basic styles for SPAN element

 */
.button {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  position: relative;

  &:hover {
  text-decoration: none;
  }

  /* shadow - with exceptions for some button types */
  box-shadow: 0px 3px 9px 0px rgba(41, 41, 51, 0.25);

  &.transparent,
  &.faux {
      box-shadow: none;
  } 

  SPAN {
    margin: 0 auto 0;
    padding: 14px 20px 12px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 16px;
    background-repeat: no-repeat;
    position:relative;
    zoom: 1;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;
    }

/* Additional text

<a class="button twoline blue"><span>Blue button<i>Additional text</i></span></a>
 */
  I {
      display: block;
      font-weight: 300;
      font-style: normal;
      text-transform: none;
      text-align: center;
    }
}



/* # Button colors  */

/* #### Blue button (default - currently orange, untill the .blue class will be replaced with .orange)

    <a class="button blue"><span>Blue button</span></a>

 */
.button.blue SPAN {
  color: @lightButtonTextColor;
  background-color: @blueButtonBgColor;

    &:hover {
    background-color: @blueButtonBgColorHover; 
    }
}

/* #### Orange button (default)

    <a class="button blue"><span>Blue button</span></a>

 */
.button.orange SPAN {
  color: @lightButtonTextColor;
  background-color: @orangeButtonBgColor;

    &:hover {
    background-color: @orangeButtonBgColorHover; 
    }
}

/* #### Green button (default)

    <a class="button green"><span>Green button</span></a>

 */
.button.green SPAN {
  color: @lightButtonTextColor;
  background-color: @greenButtonBgColor;

    &:hover {
    background-color: @greenButtonBgColorHover; 
    }
}

/* #### Grey button (secondary)

    <a class="button grey"><span>Grey button</span></a>

 */
.button.grey SPAN {
  color: @lightButtonTextColor;
  background-color: @greyButtonBgColor;

    &:hover {
    background-color: @greyButtonBgColorHover;
    }
  }

/* #### White button (for dark backgrounds)

    <a class="button white"><span>White button</span></a>

 */
.button.white SPAN {
  color: @whiteButtonTextColor;
  background-color: @whiteButtonBgColor;
  box-shadow: inset 0 0 0 1px #dde3eb;
  border: 1px solid #dde3eb\9; /* IE8 */

    &:hover {
    color: @whiteButtonTextColorHover;
    }
  }
/* #### Transparent button (for less important links, to be used on dark backgrounds)

    <a class="button transparent"><span>Transparent button</span></a>

 */
.button.transparent SPAN {
  color: @lightButtonTextColor;
  background-color: @Transparent;
  box-shadow: 0 0 0 1px #FFF inset;
  border: 1px solid #FFF\9; /* IE8 */

    &:hover {
    background-color: fadein(@Transparent, 10%);
    }
  }

/* #### Transparent button light (for less important links, to be used on light backgrounds)

    <a class="button transparent light"><span>Transparent button light</span></a>

 */
.button.transparent.light SPAN {
  color: #677280;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px #dde3eb;
  border: 1px solid #dde3eb\9; /* IE8 */
  }
.button.transparent.light SPAN:hover {
  background-color: rgba(0, 0, 0, 0.05);
  }

/* #### Greylight button

    <a class="button greylight"><span>Greylight button</span></a>

 */
.button.greylight  SPAN {
  color: @lightButtonTextColor;
  background-color: #bac1c9;
  box-shadow: inset 0 0 0 1px #aeb2b5;
  border: 1px solid #aeb2b5\9; /* IE8 */
  }
.button.greylight  SPAN:hover {
  background-color: #bac1c9;
  }

/* #### Faux button

Button that looks like link, but might be used together with buttons
No side padding - it will be vertically aligned with text

    <a class="button faux"><span>Faux button</span></a>

 */
.button.faux SPAN {
  color: @fauxButtonTextColor;
  padding: 14px 0 13px;
  background-color: transparent;
  }
.button.faux SPAN:hover {
  color: @fauxButtonTextColorHover;
  }

/* # Button border removal

Class .noborder removes border from any butten - used especially for white button */
.button.noborder SPAN {
  box-shadow: none;
  border: none\9; /* IE8 */
}



/* # Button sizes */

/* #### Normal button (basic size, no size class needed)

    <a class="button blue"><span>Normal button</span></a>

 */

/* #### Big button (size +1)

    <a class="button blue big"><span>Big button</span></a>

 */
.button.big  SPAN {
  font-size: 16px;
  padding: 17px 26px 16px;
  line-height: 17px;
  }

/* #### Large button (size +2)

    <a class="button blue big"><span>Large button</span></a>

 */
.button.large  SPAN {
  font-size: 19px;
  padding: 24px 30px 23px;
  line-height: 22px;
  }



/* # Two-line buttons */

/* #### sizes */
.button.large.twoline SPAN {
    padding: 16px 30px 17px;
}

.button.big.twoline SPAN {
    padding: 13px 26px;
}

.button.twoline SPAN {
    padding: 12px 20px 10px;
}


/* #### Second line in two-line buttons */

.button.large span i {

    font-size: 0.737em;
    line-height: 1.143;
}

.button.big span i {

    font-size: 0.813em;
    line-height: 1.385;
}

.button span i {

    font-size: 0.8em;
    line-height: 1.167;
}


/* Buttons same height as two-line buttons */

.button.large.twolineheight SPAN {
    padding: 25px 30px 24px;
}

.button.big.twolineheight SPAN {
    padding: 22px 26px;
}

.button.twolineheight span {
    padding: 18px 20px;
}




/* # Buttons with symbols */


.button {

  /* Download button */
  &.download span::before {
    display: inline-block;
    background-position: 50% 50%;
    content: "";
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    background-repeat: no-repeat; 
    background-image:  url(../i/arrow-download.png);
    }

  /* Win logo button */
  &.logo-win span::before {
      display: inline-block;
      background-position: 50% 50%;
      content: "";
      position: relative;
      top: 1px;
      width: 15px;
      height: 15px;
      margin-right: 15px;
      background-repeat: no-repeat; 
      background-image: url('../i/windows/logo-win-btn.png');
  }

  /* Arrow down button */
  &.down SPAN {
    padding-left: 40px;
    padding-right: 40px;
    background-image:  url(../i/arrows-s.png);
    background-position: 18px -28px;
    }
  /* Arrow up button */
  &.up SPAN {
    padding-left: 40px;
    padding-right: 40px;
    background-image:  url(../i/arrows-s.png);
    background-position: 18px 9px;
    }
  /* Arrow forward button */
  &.forward SPAN {
    padding-left: 40px;
    padding-right: 40px;
    background-image:  url(../i/arrows2-s.png);
    background-position: 18px 6px;
    }
  /* Arrow back button */
  &.back SPAN {
    padding-left: 40px;
    padding-right: 40px;
    background-image:  url(../i/arrows2-s.png);
    background-position: 18px -28px;
    }
  /* Arrow forward button (size +1) */
  &.big.forward SPAN {
    background-position: 18px 13px;
    }
  /* Arrow back button (size +1) */
  &.big.back SPAN {
    background-position: 18px -72px;
    }
}
