@charset "utf-8";
/* CSS Document */

/* # TABLET

min-width: 768px, max-width 1008px, dpi > 1.2 :: overrides desktop settings ------------------------------------- */

body:after {
    content: 'widescreen';
    display: none;
}	


/* # HEADER  */

#header,
#header .bg-white {
	min-width: 0;
	width: auto;
}

#header .AVsizer {
	min-width: 768px;
	max-width: 1200px;
	}

	
/* # CONTENT */	
#content-holder {
	width: 100%;
	min-width: 768px;
	}
		
/* # AVsizer 

defines width of main elements - header, content, footer */
.AVsizer {
    width: auto;
	}
	
	
/* # TABS */
#tabs-anchor {
		 display: none;
		 }

